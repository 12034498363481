import { makeObservable, observable, reaction, runInAction, set } from 'mobx';
import { Website } from './settings';
import { DefaultFetcher } from './utils';

// Create a session store
export let SessionStore = observable({
    Active: 'home',
});

// Create a toast store (name, id)
export let Toasts = observable.map();

export default SessionStore;
window.SessionStore = SessionStore;
