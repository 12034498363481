import {
    Outlet,
    Link,
    useMatches,
    useNavigate,
} from "react-router-dom";
import { useState, useEffect } from 'react';
import { Moon, Sun, Menu, X, Github, Twitter, Linkedin, HomeIcon, User, Mail, Settings, HelpCircle, ChevronRight, ChevronLeft, PanelLeft, PanelLeftClose } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Nav, MobileNav } from '@/components/Nav';
import { Sidenav } from '@/components/SideNav';
import { observer } from 'mobx-react-lite';
import { observable, runInAction } from "mobx";
import { Toaster } from "@/components/ui/sonner";
import { Toasts } from "@/lib/stores";
import { useBrowserStore } from "@/lib/hooks/browserStore";
import { toast } from "sonner";
import { set } from "mobx";
import ErrorBoundary from '@/components/ui/custom/errorboundary';

const Layout = observer(({ store }) => {

    const matches = useMatches();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [sidePanelOpen, setSidePanelOpen] = useBrowserStore("side-panel-open", false, localStorage)
    const navigate = useNavigate();

    useEffect(() => {
        runInAction(() => set(store, { Active: matches[matches.length - 1].id }));
    }, [matches]);

    const toggles = {
        mobileMenu: () => setMobileMenuOpen(!mobileMenuOpen),
        sidePanel: () => setSidePanelOpen(!sidePanelOpen)
    }

    return (
        <>
            <div className="dark:bg-gray-900 bg-gray-100 min-h-screen">
                {/* Header */}
                <header
                    className="sticky top-0 left-0 right-0 z-50 h-20 bg-white dark:bg-gray-800"
                >
                    <div className="container mx-auto px-4 h-full flex items-center justify-between">
                        <div className="flex items-center justify-between">
                            {/* <div className="flex w-6 h-12 mr-6 cursor-pointer justify-center items-center" onClick={() => setSidePanelOpen(!sidePanelOpen)}>
                                {
                                    sidePanelOpen ?
                                        <PanelLeftClose className="w-6 h-6" />
                                        :
                                        <PanelLeft className="w-6 h-6" />
                                }
                            </div> */}
                            <div className="flex w-12 h-12 mr-4 justify-center items-center">
                               [logo]
                            </div>
                            <div className="flex flex-col">
                                <h1 className="font-bold text-xl dark:text-white">
                                    LTDev LLC
                                </h1>
                                <p className="text-gray-600 dark:text-gray-400 text-xs">
                                    Software Services
                                </p>
                            </div>
                        </div>
                        <Nav store={store} options={{ mobileMenuOpen }} toggles={toggles} />
                    </div>
                    <MobileNav store={store} options={{ mobileMenuOpen }} toggles={toggles} />
                </header>

                {/* Content */}
                <div className="flex">
                    {/* <Sidenav options={{ sidePanelOpen }} store={store} toggles={toggles} /> */}
                    <main className={`flex-grow ${sidePanelOpen ? 'pl-1 ml-12' : 'ml-3'} mr-3`}>
                        <ErrorBoundary>
                            <Outlet />
                        </ErrorBoundary>
                    </main>
                </div>

                {/* Footer */}
                <footer className="z-30  bg-white dark:bg-gray-800 py-8 sticky top-[100vh]">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-col md:flex-row justify-between items-center">
                            <div className="mb-4 md:mb-0">
                            <h2 className="text-xs text-gray-800 dark:text-white mb-2">
                                    <a href="/policies/privacy" className="underline" target="_blank" rel="noopener">Privacy Policy</a> /&nbsp;
                                    <a href="/policies/terms" className="underline" target="_blank" rel="noopener">Terms Of Service</a> /&nbsp;
                                    <a href="/policies/cookies" className="underline" target="_blank" rel="noopener">Cookie Policy</a>
                                </h2>
                                <p className="text-sm text-gray-600 dark:text-gray-300">
                                    &copy; 2024 LTDev LLC. All rights reserved.
                                </p>
                            </div>
                            <div className="flex space-x-4">
                                <a href="#" className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white" onClick={() => toast("Coming Soon!")}>
                                    <Github className="h-6 w-6" />
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            <Toaster richColors />
        </>
    )
})

export default Layout;