import { Moon, Sun } from "lucide-react";
import { createContext, useContext, useEffect, useState } from 'react';

const initialState = {
    theme: "system",
    setTheme: () => null,
    toggleTheme: () => null,
    Icon: Moon,
}

const ThemeProviderContext = createContext(initialState)

export function ThemeProvider({
    children,
    defaultTheme = "system",
    storageKey = "darkmode",
    ...props
}) {
    // TODO: Allow for live update checks of system theme
    const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
        ? "dark"
        : "light";

    const [theme, setTheme] = useState(
        () => localStorage.getItem(storageKey) || defaultTheme
    )

    useEffect(() => {
        const root = window.document.documentElement
        root.classList.remove("light", "dark")
        if (theme === "system") {
            root.classList.add(systemTheme)
            root.dataset.colorMode = systemTheme
            return
        }
        root.classList.add(theme)
        root.dataset.colorMode = theme
    }, [theme])

    const value = {
        theme,
        systemTheme,
        setTheme: theme => {
            localStorage.setItem(storageKey, theme)
            setTheme(theme)
        }
    }
    
    value.toggleTheme = () => value.setTheme(value.theme === "light" ? "dark" : "light")

    value.Icon = (
        value.theme === "dark" ||
        (value.theme === "system" && systemTheme === "dark")
    ) ? Sun : Moon

    return (
        <ThemeProviderContext.Provider {...props} value={value}>
            {children}
        </ThemeProviderContext.Provider>
    )
}

export const useTheme = () => {
    const context = useContext(ThemeProviderContext)

    if (context === undefined)
        throw new Error("useTheme must be used within a ThemeProvider")

    return context
}

export const ThemeButton = (props) => {
    const {
        buttonClasses = "text-gray-600 dark:text-gray-100",
        iconClasses = "w-5 h-5"
    } = props || {}

    const context = useContext(ThemeProviderContext)

    return (
        <button
            type="button"
            onClick={context.toggleTheme}
            className={buttonClasses}
        >
            <context.Icon className={iconClasses} />
        </button>
    )
}