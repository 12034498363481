import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
    return twMerge(clsx(inputs))
}

// A default fetch wrapper to handle auth and other scenarios
// TODO: Improve this
export function Fetcher(redirect = false) {
    return async (url, options) => {
        try {
            const res = await fetch(url, {
                ...options,
                headers: {
                    "Content-Type": "application/json",
                    ...options?.headers || {},
                },
                credentials: 'include',
            });
            switch (res.status) {
                case 401:
                    return Promise.reject(redirect ? window.location.replace(redirect) : window.location.reload());
            }
            return res
        } catch (err) {
            return Promise.reject(err);
        }
    }
}
const DefaultFetcher = Fetcher()

export {
    DefaultFetcher
}