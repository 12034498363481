import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, RouterProvider } from "react-router-dom";
import "./assets/css/main.scss";
import router from "./routes";
import { ThemeProvider } from "@/lib/providers/theme";
import { toJS } from "mobx";
import Loader from "@/components/ui/custom/loader";

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <ThemeProvider defaultTheme="system">
            <RouterProvider
                router={router}
                fallbackElement={<Fallback />}
                future={{ v7_startTransition: true }}
            />
        </ThemeProvider>
    </React.StrictMode>
);

function Fallback() {
    let dark = true;
    try {
        dark = window.matchMedia('(prefers-color-scheme: dark)').matches
    } catch { }
    return (
        <div className={`min-h-screen ${dark ? "dark" : "light"}`}>
            <div className="dark:bg-gray-900 bg-gray-100 min-h-screen flex justify-center items-center">
                <Loader />
            </div>
        </div>
    );
}